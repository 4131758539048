<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <material-preview v-if="newMaterial" :material="newMaterial" />
    </v-main>
  </v-container>
</template>

<script>
import MaterialPreview from '@/components/material/MaterialPreview'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Preview',
  components: {
    MaterialPreview
  },
  computed: {
    ...mapState('materials', ['newMaterial'])
  },
  mounted () {
    if (!this.newMaterial) {
      this.$router.push({ name: 'Materials' })
    }
  },
  async beforeDestroy () {
    const to = this.$router.history.current
    if (to.name !== 'EditMaterial' && to.name !== 'AddMaterial') {
      await this.setNewMaterial(null)
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (to.name === 'EditMaterial' || to.name === 'AddMaterial' || !this.$store.state.materials.materialFormIsModified) {
      next()
      return
    }
    const res = confirm('¿Estás seguro? Si sales perderás los cambios no guardados.')
    if (res) {
      next()
    }
  },
  methods: {
    ...mapActions('materials', ['setNewMaterial'])
  }

}
</script>

<style scoped lang="scss">

</style>
