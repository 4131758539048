<template>
  <div>
    <div style="position: relative">
      <v-btn text class="return-button" style="position: absolute;" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
        <p class="mb-0 mx-3">
          Back
        </p>
      </v-btn>
      <p class="material__title">
        {{ localMaterial.name }}
      </p>
    </div>
    <v-card class="material__content">
      <template v-for="(block, index) in localMaterial.blocks">
        <div :key="index" :class="{'mb-10':index + 1 !== localMaterial.blocks.length}">
          <!--  Rich Text preview -->
          <div v-if="block.type === 'text'" class="ck ck-content" v-html="block.content" />

          <!-- Image preview -->
          <div class="d-flex justify-center align-center">
            <v-img v-if="block.type === 'image'" class="image-preview" :src="block.fileUrl"
                   max-width="90%"
            />
          </div>

          <!-- View preview -->
          <div v-if="block.type === 'video'" class="d-flex align-center justify-center">
            <video :key="index" :autoplay="false" controls="controls" class="mx-auto" :src="block.fileUrl"
                   style="max-width: 90%;"
            />
          </div>

          <!-- Button preview -->
          <div v-if="block.type === 'button'" class="d-flex flex-column">
            <p v-if="block.content" class="mb-12">
              {{ block.content }}
            </p>
            <base-button elevation="0" color="#243674" class="px-7 mx-auto" @click="goToExternalLink(block.buttonUrl)">
              {{ block.buttonName }}
            </base-button>
          </div>

          <!-- File preview -->
          <div v-if="block.type === 'file'" class="d-flex flex-column">
            <div v-if="block.fileUrl" class="file-preview mx-auto">
              <vue-pdf-embed class="file-preview__file-container" :source="block.fileUrl" :page="1" height="186"
                             width="296"
              />
              <div class="file-preview__name">
                {{ block.fileName }}
              </div>
              <v-btn color="#FFFFFF" class="file-preview__button" height="73px" width="296px"
                     @click="goToExternalLink(block.fileUrl)"
              >
                <div class="d-flex flex-column ">
                  <v-icon color="#4C4C4C">
                    mdi-download
                  </v-icon>
                  Descargar Pdf
                </div>
              </v-btn>
            </div>
            <p v-if="block.content" class="mt-10">
              {{ block.content }}
            </p>
          </div>

          <!-- Audio preview -->
          <template v-if="block.type === 'audio'" class="d-flex flex-column">
            <p v-if="block.content" class="mb-12">
              {{ block.content }}
            </p>
            <div class="d-flex align-center">
              <div class="d-flex align-center audio-preview mx-auto">
                <answer-player ref="audioPlayer" class="ma-0" :audio-url="block.fileUrl" />
                <!--                <base-button-->
                <!--                  class="audio-preview__button"-->
                <!--                  elevation="0"-->
                <!--                  color="#243674"-->
                <!--                  height="56px"-->
                <!--                  @click="playAudio"-->
                <!--                >-->
                <!--                  Escuchar audio-->
                <!--                </base-button>-->
              </div>
            </div>
          </template>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import BaseButton from '@/components/buttons/BaseButton'
import { goToExternalLink } from '@/utils/commonFunctions'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import AnswerPlayer from '@/components/answer/AnswerPlayer'

// This line is to import all rich text styles
// eslint-disable-next-line no-unused-vars
import RichText from '@/components/inputs/RichText'

export default {
  name: 'MaterialPreview',
  components: { BaseButton, VuePdfEmbed, AnswerPlayer },
  props: ['material'],
  data () {
    return {
      localMaterial: this.material,
      goToExternalLink
    }
  },
  mounted () {
    console.log('mounted')
    this.localMaterial.blocks = this.localMaterial.blocks.map((block) => {
      if (block.file) {
        this.getFileUrl(block)
      }
      return block
    })
    console.log(this.localMaterial)
  },
  methods: {
    getFileUrl (block) {
      const reader = new FileReader()
      reader.readAsDataURL(block.file)
      reader.onload = (e) => {
        this.$set(block, 'fileUrl', e.target.result)
      }
    },
    playAudio () {
      this.$refs.audioPlayer[0].togglePlay()
    }
  }
}
</script>

<style scoped lang="scss">
.material {
  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 35px;
    color: #0091C9;
    text-align: center;
    margin-bottom: 16px;
  }

  &__content {
    background: #F7F7F7;
    border: 1px solid #747474;
    border-radius: 15px;
    padding: 32px 36px;
  }
}

.image-preview {
  border-radius: 0 !important;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
}

.file-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 296px;

  &__file-container {
    width: 296px;
    height: 186px;
    background-color: rgb(196, 196, 196);
    overflow: hidden;
    border: 1px solid rgb(192, 192, 192);
  }

  &__name {
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #4C4C4C;
    text-align: center;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    letter-spacing: 0;
    text-transform: none;
    font-size: 16px;
    line-height: 22px;
    color: #4C4C4C;
    font-weight: 700;
    border-radius: 0 !important;
    margin-top: 1px;
  }
}

.audio-preview {
  border: 2px solid #243674;
  border-radius: 15px!important;
  overflow: hidden;

  &__button {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    min-height: 56px;
    height: 56px;
  }

  ::v-deep .volumen-controls{
    margin-right: 0!important;
  }
}

.return-button {
  letter-spacing: 0;
  text-transform: none;
  font-size: 18px;
}
</style>
